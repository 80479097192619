import "./src/styles/style.css";

import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { navigate } from "gatsby-link";

const onRedirectCallback = (appState) => {
  navigate(appState?.returnTo || "/", { replace: true });
};

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain="dev-3otjc9he.eu.auth0.com"
      clientId="xjeRs5xGwwZuYvSNM9cwgWAZBfk6QvGy"
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
      audience="https://dev-3otjc9he.eu.auth0.com/api/v2/"
      scope="update:current_user_metadata"
    >
      {element}
    </Auth0Provider>
  );
};
