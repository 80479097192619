// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anlass-js": () => import("./../../../src/pages/anlass.js" /* webpackChunkName: "component---src-pages-anlass-js" */),
  "component---src-pages-artikel-js": () => import("./../../../src/pages/artikel.js" /* webpackChunkName: "component---src-pages-artikel-js" */),
  "component---src-pages-geschenkideen-js": () => import("./../../../src/pages/geschenkideen.js" /* webpackChunkName: "component---src-pages-geschenkideen-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-person-js": () => import("./../../../src/pages/person.js" /* webpackChunkName: "component---src-pages-person-js" */),
  "component---src-pages-profil-js": () => import("./../../../src/pages/profil.js" /* webpackChunkName: "component---src-pages-profil-js" */),
  "component---src-templates-article-page-jsx": () => import("./../../../src/templates/article-page.jsx" /* webpackChunkName: "component---src-templates-article-page-jsx" */),
  "component---src-templates-blogpost-page-jsx": () => import("./../../../src/templates/blogpost-page.jsx" /* webpackChunkName: "component---src-templates-blogpost-page-jsx" */),
  "component---src-templates-event-page-jsx": () => import("./../../../src/templates/event-page.jsx" /* webpackChunkName: "component---src-templates-event-page-jsx" */),
  "component---src-templates-instruction-page-jsx": () => import("./../../../src/templates/instruction-page.jsx" /* webpackChunkName: "component---src-templates-instruction-page-jsx" */),
  "component---src-templates-item-page-jsx": () => import("./../../../src/templates/item-page.jsx" /* webpackChunkName: "component---src-templates-item-page-jsx" */),
  "component---src-templates-person-page-jsx": () => import("./../../../src/templates/person-page.jsx" /* webpackChunkName: "component---src-templates-person-page-jsx" */)
}

